
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import backgroundBanner from '../../assets/images/onboarding_layoutLogin.png';
import SEO from '../seo';

const useStyles = makeStyles(theme => ({
  main: {
    background:theme.palette.grayScale.g0,
    margin: '0 auto',
    paddingTop: theme.space.vertical.desktop,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: theme.space.vertical.mobile,
    },
    background:'#FFFFFF'
  },
  banner: {
    background: `url(${backgroundBanner})`,
    backgroundSize: 'cover',
    backgroundRepeat:'no-repeat',
    backgroundPosition:'center',
    borderRadius: '0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginBottom:'2rem'
  },
}));

const OnboardingLayout = ({ location, pageContext, children, title, meta, description, isLogginPage }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flex="1 0 50%" height="100vh" width="100%" className={classes.main}>
      <SEO title={title} meta={meta} description={description}/>
      <Box className={classes.banner} width="100%" display="flex" flexDirection="column" justifyContent="flex-end">
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        overflow="auto"
        background='#FFFFFF !important'
      >
        {children}
      </Box>
    </Box>);
};

OnboardingLayout.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
  children: PropTypes.node,
  title: PropTypes.string,
  meta: PropTypes.object,
  description: PropTypes.string,
};

OnboardingLayout.defaultProps = {
  location: {},
  pageContext: {},
  title: '',
  description: ''
};

export default OnboardingLayout;
