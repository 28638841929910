import React, { useState } from 'react';
import { Box, Link, makeStyles } from '@material-ui/core';
import Text from '../common/text.component';
import {
  MESSAGE_EXPIRED_CODE,
} from './const';
import CodeInput from '../common/CodeInput';
import { validateMinLength } from '../../utils/validators.utils';
const EMAIL_SPAM_MESSAGE =
'Si no ves el e-mail con el código en tu bandeja de entrada, revisá la carpeta de spam o correo no deseado.';
const EMAIL_CONFIRMATION_MESSAGE = '¿No recibiste el e-mail?';
const MESSAGE_SEND_CODE = 'Enviar código';
const MESSAGE_RESEND_CODE = 'Reenviar';



const useStyle = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '25rem',
    maxHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    marginBottom: '0.5rem',
  },
  subtitle: {
    marginBottom: '0.5rem',
  },
  email: {
    marginBottom: '4.875rem',
    wordBreak: 'break-all'
  },
  registerForm: {
    marginBottom: '3.5rem',
    position: 'relative',
  },
  confirmationMessage: {
    marginTop: '0.75rem',
  },
  link: {
    marginLeft: '0.625rem',
    color: theme.palette.primary.main2,
    fontSize:'1rem',
    '&:hover':{
      color:theme.palette.primary.dark,
    }
  },
  divLoading: {
    opacity: '0.5',
  },
}));

export const PasswordActivationForm = ({
  fields,
  handleOnSubmit = () => {},
  setFields = () => {},
  isFocused = () => {},
  loading = false,
  errorMessage = '',
  title = '',
  subTitle = '',
  email = '',
  hasFocus = false,
  setHasFocus = ()=>{},
  handleResendCode = ()=>{},
}) => {
  const classes = useStyle();


  const validateFields = (newFields) => {
    const isValidCharacter1 = validateMinLength(newFields.character1, 1);
    const isValidCharacter2 = validateMinLength(newFields.character2, 1);
    const isValidCharacter3 = validateMinLength(newFields.character3, 1);
    const isValidCharacter4 = validateMinLength(newFields.character4, 1);

    const valid =
    isValidCharacter1.valid &&
    isValidCharacter2.valid &&
    isValidCharacter3.valid &&
    isValidCharacter4.valid;
    if(valid){
      setHasFocus(false);
      handleOnSubmit(newFields);
    };
    return valid ;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={loading && classes.divLoading}
    >
      <Box className={classes.container}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Text className={classes.title} primary large semibolder disabled>
            {title}
          </Text>
          <Text className={classes.subtitle} little g600 center>
            {subTitle}
          </Text>
          <Text className={classes.email} small g500 semibolder center>
            {email}
          </Text>
          <Box className={classes.registerForm}>
            <CodeInput
              fields={fields}
              length= {4}
              setFields={setFields}
              validateFields={validateFields}
              errorMessage={errorMessage}
              loading={loading}
              hasFocus={hasFocus}
            />
          </Box>
          {errorMessage === MESSAGE_EXPIRED_CODE ? (
            <>
              <Link
                href="#"
                className={classes.link}
                onClick={handleResendCode}
              >
                {MESSAGE_SEND_CODE}
              </Link>
            </>
          ) : (
            <>
              <Text little g600 center>
                {EMAIL_SPAM_MESSAGE}
              </Text>
              <Box className={classes.confirmationMessage}>
                <Text small g600 center >
                  {EMAIL_CONFIRMATION_MESSAGE}
                </Text>
                <Link
                  href="#"
                  className={classes.link}
                  onClick={handleResendCode}
                >
                  {MESSAGE_RESEND_CODE}
                </Link>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
